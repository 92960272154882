define("ember-svg-jar/inlined/claim-ethereum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M90.4 1L43 80.3 90.4 97l46.9-17.2L90.3 1zm0 0v96\" stroke=\"#999\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M121.3 85.7l15.2 4.7-46.3 18.8L44 90.4l14.8-4.5\" stroke=\"#999\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M136.4 90.4l-46 80.6L44 90.4m46.2 18.8l.2 61.8m46.9-91.2L90.3 64 43.2 80.3m129.3-4.7c4.5 4 7 8.3 7 13 0 11.5-15.5 21.7-39.3 27.7M151 64a82 82 0 0110.3 4.3M134 59.5l8.6 2M33.8 62.7c3.2-1 6.6-2 10.3-2.7m-33.3 43.8C4.5 99.2 1 94.1 1 88.6c0-9 9.3-17 24.7-23m20 52c-7.9-1.7-15-4-21.1-6.4\" stroke=\"#999\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M154.5 37.9l.7 1.3 1.4.8-1.4.7-.7 1.4-.8-1.4-1.3-.7 1.3-.8.8-1.3zm3 44.7l.7 1.3 1.4.7-1.4.8-.7 1.3-.8-1.3-1.3-.8 1.3-.7.8-1.3zM24.6 84.9l.7 1.4 1.3.7-1.3.7-.7 1.4-.8-1.4-1.3-.7 1.3-.7.8-1.4zm-1.3-35.5l.7 1.4 1.3.7-1.3.8-.7 1.3-.8-1.3-1.3-.8 1.3-.7.8-1.4zm31.1-25.1l.8 1.4 1.4.7-1.4.8-.8 1.4-.7-1.4-1.3-.8 1.3-.7.7-1.4zm68.8-4.1l.8 1.3 1.3.7-1.3.8-.8 1.3-.7-1.3-1.4-.8 1.4-.7.7-1.3z\" fill=\"#999\"/>",
    "attrs": {
      "width": "181",
      "height": "172",
      "viewBox": "0 0 181 172",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});