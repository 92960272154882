define("ember-svg-jar/inlined/website-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.7 6.3H6.4c-.2 0-.4.2-.4.4v2.6c0 .2.3.4.5.4h4.2c.3 0 .4-.2.4-.4l.1-1.3V6.7c-.2-.2-.3-.4-.5-.4zm6.3.3c0-.1-.1-.3-.4-.3h-3.3c-.3 0-.5.2-.5.4v2.6c0 .2.2.4.5.4h3.3c.2 0 .4-.2.4-.3l.1-1.4V6.6zm-4.5-2.4c0 .2.3.4.4.4h2.9c.3 0 .5-.4.3-.6a9.1 9.1 0 00-4.3-4c-.3-.1-.7.4-.5.7.5 1 1 2.2 1.2 3.5zm-5.6.4h3.4c.2 0 .5-.3.4-.5L10 2C9.3.4 8.7.3 8.6.3 8.5.3 7.8.5 7 2l-.7 2c0 .3.2.6.5.6zM5.3 0A8.7 8.7 0 001 4c-.1.2 0 .6.4.6h2.8c.2 0 .3-.2.4-.4C5 3 5.3 1.7 5.8.8c.2-.4-.1-.9-.5-.8zm-1 9.2V6.6c0-.2-.2-.4-.4-.4H.5c-.2 0-.3.2-.4.3L0 8v1.4c0 .1.3.3.5.3H4c.2 0 .5-.2.4-.5zm6 2.2H6.9c-.3 0-.6.3-.5.5l.7 2.1c.7 1.6 1.4 1.7 1.5 1.7 0 0 .7-.2 1.4-1.7l.7-2c0-.3-.2-.6-.4-.6zm-5.7.4c0-.2-.2-.4-.4-.4H1.4c-.4 0-.5.4-.4.6 1 1.7 2.5 3.2 4.3 4 .4.1.7-.4.5-.7-.5-1-1-2.2-1.2-3.5zm11.2-.4h-2.9l-.4.4c-.3 1.3-.7 2.5-1.2 3.4-.2.3.2.9.5.7a8.4 8.4 0 004.3-4c.2-.1 0-.5-.3-.5z\" fill=\"#90A09D\"/>",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});